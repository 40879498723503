*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

:root,
html {
  scroll-behavior: smooth;
}

::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

::-moz-placeholder, textarea::-moz-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

:-ms-input-placeholder, textarea:-ms-input-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

::-ms-input-placeholder, textarea::-ms-input-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

input,
textarea,
::placeholder,
textarea::placeholder,
a {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

a {
  text-decoration: none;
  display: inline-block;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
button {
  font-weight: bold;
}
h1 span,
h1 p,
h2 span,
h2 p,
h3 span,
h3 p,
h4 span,
h4 p,
h5 span,
h5 p,
h6 span,
h6 p,
a span,
a p,
button span,
button p {
  font-weight: bold;
}

ul {
  list-style-type: none;
}

/* FONT IMPORTS */
@font-face {
  font-family: "Century Gothic";
  src: url("../../fonts/CenturyGothic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Century Gothic";
  src: url("../../fonts/GOTHICBI.TTF") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Century Gothic";
  src: url("../../fonts/GOTHICB.TTF") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.clr-primary {
  color: #052769;
}

.bg-primary {
  background-color: #052769;
}

.clr-d-blue {
  color: #0c2045;
}

.bg-d-blue {
  background-color: #0c2045;
}

.clr-placeholder {
  color: #6b7c9e;
}

.bg-placeholder {
  background-color: #6b7c9e;
}

.clr-inputBorder {
  color: #dbe3f0;
}

.bg-inputBorder {
  background-color: #dbe3f0;
}

.clr-inputBg {
  color: #f5f8fe;
}

.bg-inputBg {
  background-color: #f5f8fe;
}

.clr-accentGreen {
  color: #93e396;
}

.bg-accentGreen {
  background-color: #93e396;
}

.clr-secBtn {
  color: #e3ecfc;
}

.bg-secBtn {
  background-color: #e3ecfc;
}

.clr-grey {
  color: #edf1f8;
}

.bg-grey {
  background-color: #edf1f8;
}

.clr-blue-200 {
  color: #fafcff;
}

.bg-blue-200 {
  background-color: #fafcff;
}

.clr-blue-100 {
  color: #f5f8fe;
}

.bg-blue-100 {
  background-color: #f5f8fe;
}

.clr-white {
  color: #fff;
}

.bg-white {
  background-color: #fff;
}

.clr-error {
  color: #ff1111;
}

.bg-error {
  background-color: #ff1111;
}

.clr-accentBlue {
  color: #4682f6;
}

.bg-accentBlue {
  background-color: #4682f6;
}

.clr-primary {
  color: #052769;
}

.bg-primary {
  background-color: #052769;
}

.clr-d-blue {
  color: #0c2045;
}

.bg-d-blue {
  background-color: #0c2045;
}

.clr-placeholder {
  color: #6b7c9e;
}

.bg-placeholder {
  background-color: #6b7c9e;
}

.clr-inputBorder {
  color: #dbe3f0;
}

.bg-inputBorder {
  background-color: #dbe3f0;
}

.clr-inputBg {
  color: #f5f8fe;
}

.bg-inputBg {
  background-color: #f5f8fe;
}

.clr-accentGreen {
  color: #93e396;
}

.bg-accentGreen {
  background-color: #93e396;
}

.clr-secBtn {
  color: #e3ecfc;
}

.bg-secBtn {
  background-color: #e3ecfc;
}

.clr-grey {
  color: #edf1f8;
}

.bg-grey {
  background-color: #edf1f8;
}

.clr-blue-200 {
  color: #fafcff;
}

.bg-blue-200 {
  background-color: #fafcff;
}

.clr-blue-100 {
  color: #f5f8fe;
}

.bg-blue-100 {
  background-color: #f5f8fe;
}

.clr-white {
  color: #fff;
}

.bg-white {
  background-color: #fff;
}

.clr-error {
  color: #ff1111;
}

.bg-error {
  background-color: #ff1111;
}

.clr-accentBlue {
  color: #4682f6;
}

.bg-accentBlue {
  background-color: #4682f6;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

:root,
html {
  scroll-behavior: smooth;
}

::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

::-moz-placeholder, textarea::-moz-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

:-ms-input-placeholder, textarea:-ms-input-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

::-ms-input-placeholder, textarea::-ms-input-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

input,
textarea,
::placeholder,
textarea::placeholder,
a {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

a {
  text-decoration: none;
  display: inline-block;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
button {
  font-weight: bold;
}
h1 span,
h1 p,
h2 span,
h2 p,
h3 span,
h3 p,
h4 span,
h4 p,
h5 span,
h5 p,
h6 span,
h6 p,
a span,
a p,
button span,
button p {
  font-weight: bold;
}

ul {
  list-style-type: none;
}

/* FONT IMPORTS */
@font-face {
  font-family: "Century Gothic";
  src: url("../../fonts/CenturyGothic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Century Gothic";
  src: url("../../fonts/GOTHICBI.TTF") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Century Gothic";
  src: url("../../fonts/GOTHICB.TTF") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.chatBox {
  width: 100%;
  height: 67px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: height 0.4s ease;
  transition: height 0.4s ease;
  overflow: hidden;
}
.chatBox .loading-chats {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  flex-direction: column;
}
.chatBox .loading-chats svg {
  margin-bottom: 0.7rem;
  width: 20px;
  height: 20px;
}
.chatBox.showChat {
  height: 80%;
  max-height: 80%;
}
.chatBox header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0px 2px 8px rgba(5, 39, 105, 0.1);
          box-shadow: 0px 2px 8px rgba(5, 39, 105, 0.1);
  border-radius: 8px 8px 0px 0px;
  padding: 0 0.8rem;
}
.chatBox header .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.chatBox header .left svg {
  color: #052769;
  width: 28px;
  height: 26px;
}
.chatBox header .left h1 {
  margin-left: 1rem;
}
.chatBox .chat-body {
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
  border-radius: 0 0 4px 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding: 1rem 1rem 0.4rem 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f5f8fe;
}
.chatBox .chat-body .chat-wrap {
  padding: 1rem;
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: auto;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.chatBox .chat-body .chat-heading {
  border-bottom: 1px solid #edf1f8;
}
.chatBox .chat-body .chat-heading .top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
}
.chatBox .chat-body .chat-heading img {
  margin-right: 1rem;
  width: 48px;
  height: 48px;
  border-radius: 4px;
}
.chatBox .chat-body .chat-heading h3 {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}
.chatBox .chat-body .chat-heading p {
  font-size: 0.9rem;
  font-weight: 400;
}
.chatBox .chat-body .chat-heading .bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0.5rem 0;
}
.chatBox .chat-body .chat-heading .btn {
  min-width: unset;
  font-size: 0.7rem;
  padding: 0.5rem;
  margin: 0 0.5rem;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}
.chatBox .chat-body .chat-heading .checkbox-wrapper {
  margin-bottom: 0;
}
.chatBox .chat-body .chat-heading .checkbox-wrap {
  padding: 0.4rem;
}
.chatBox .chat-body .chat-heading .checkbox-wrap label {
  font-size: 0.7rem;
}
.chatBox .chat-body .chat-heading .checkbox-wrap input {
  width: 15px;
  height: 15px;
  margin-right: 0.4rem;
  padding: 0;
}
.chatBox .chat-body .chat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 1rem 0;
}
.chatBox .chat-body .chat p {
  font-size: 13px;
  padding: 8px 12px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
}
.chatBox .chat-body .chat .status {
  font-size: 10px;
  margin-top: 0.3rem;
}
.chatBox .chat-body .chat.receive {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.chatBox .chat-body .chat.receive p {
  border-radius: 16px 16px 16px 0px;
}
.chatBox .chat-body .chat.sender {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.chatBox .chat-body .chat.sender p {
  border-radius: 16px 16px 0px 16px;
}
.chatBox .chat-footer form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 0.5rem;
}
.chatBox .chat-footer form .form-group {
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
  width: auto;
}
.chatBox .chat-footer form .btn:not(.btn:last-child),
.chatBox .chat-footer form .form-group {
  margin-right: 0.5rem;
}
.chatBox .chat-footer form .btn {
  width: 40px;
  height: 40px;
  padding: 0;
}

.pd-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  min-height: 100vh;
  background-color: #fafcff;
  margin: auto;
  z-index: -1;
}
.pd-layout-main {
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
  height: 100%;
  padding: 2rem 1rem;
}
.pd-layout-main .inner {
  max-width: 900px;
  margin: auto;
  width: 80%;
}
@media only screen and (max-width: 768px) {
  .pd-layout-main .inner {
    width: 100%;
  }
}
.pd-layout-main-full {
  max-width: unset;
  width: 100%;
}
.pd-layout-sidebar {
  padding: 0rem 0.5rem 0 0.5rem;
  height: 100vh;
  width: 28%;
  min-width: 390px;
  max-width: 400px;
  position: sticky;
  top: 0;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  flex-direction: column;
}
.pd-layout-sidebar.flex-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
@media only screen and (max-width: 900px) {
  .pd-layout {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    padding: 1rem;
  }
  .pd-layout-sidebar, .pd-layout-main {
    width: 100%;
    max-width: unset;
    min-width: unset;
    padding: 2rem 0;
    position: relative;
    height: auto;
  }
}

.notifications {
  min-height: 100vh;
}
.notifications .info-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.notifications .info-wrap .btn.round-btn {
  padding: 0.7rem;
  margin: 0;
  display: none;
}
@media only screen and (max-width: 900px) {
  .notifications .info-wrap .btn.round-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.notifications .msg-info {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0.3rem 0;
  padding: 1rem;
}
.notifications .msg-info img.info-img {
  width: 70px;
  height: 70px;
  margin-right: 15px;
  border-radius: 8px;
}
.notifications .msg-info .title {
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 17px;
  margin-bottom: 5px;
}
.notifications .msg-info .message {
  color: #8595b2;
}
.notifications .main {
  padding: 1rem;
  width: 100%;
  max-width: 1200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 2rem auto;
}
.notifications .messages-wrap {
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
}
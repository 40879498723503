*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

:root,
html {
  scroll-behavior: smooth;
}

::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

::-moz-placeholder, textarea::-moz-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

:-ms-input-placeholder, textarea:-ms-input-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

::-ms-input-placeholder, textarea::-ms-input-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

input,
textarea,
::placeholder,
textarea::placeholder,
a {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

a {
  text-decoration: none;
  display: inline-block;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
button {
  font-weight: bold;
}
h1 span,
h1 p,
h2 span,
h2 p,
h3 span,
h3 p,
h4 span,
h4 p,
h5 span,
h5 p,
h6 span,
h6 p,
a span,
a p,
button span,
button p {
  font-weight: bold;
}

ul {
  list-style-type: none;
}

/* FONT IMPORTS */
@font-face {
  font-family: "Century Gothic";
  src: url("../../fonts/CenturyGothic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Century Gothic";
  src: url("../../fonts/GOTHICBI.TTF") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Century Gothic";
  src: url("../../fonts/GOTHICB.TTF") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.clr-primary {
  color: #052769;
}

.bg-primary {
  background-color: #052769;
}

.clr-d-blue {
  color: #0c2045;
}

.bg-d-blue {
  background-color: #0c2045;
}

.clr-placeholder {
  color: #6b7c9e;
}

.bg-placeholder {
  background-color: #6b7c9e;
}

.clr-inputBorder {
  color: #dbe3f0;
}

.bg-inputBorder {
  background-color: #dbe3f0;
}

.clr-inputBg {
  color: #f5f8fe;
}

.bg-inputBg {
  background-color: #f5f8fe;
}

.clr-accentGreen {
  color: #93e396;
}

.bg-accentGreen {
  background-color: #93e396;
}

.clr-secBtn {
  color: #e3ecfc;
}

.bg-secBtn {
  background-color: #e3ecfc;
}

.clr-grey {
  color: #edf1f8;
}

.bg-grey {
  background-color: #edf1f8;
}

.clr-blue-200 {
  color: #fafcff;
}

.bg-blue-200 {
  background-color: #fafcff;
}

.clr-blue-100 {
  color: #f5f8fe;
}

.bg-blue-100 {
  background-color: #f5f8fe;
}

.clr-white {
  color: #fff;
}

.bg-white {
  background-color: #fff;
}

.clr-error {
  color: #ff1111;
}

.bg-error {
  background-color: #ff1111;
}

.clr-accentBlue {
  color: #4682f6;
}

.bg-accentBlue {
  background-color: #4682f6;
}

.clr-primary {
  color: #052769;
}

.bg-primary {
  background-color: #052769;
}

.clr-d-blue {
  color: #0c2045;
}

.bg-d-blue {
  background-color: #0c2045;
}

.clr-placeholder {
  color: #6b7c9e;
}

.bg-placeholder {
  background-color: #6b7c9e;
}

.clr-inputBorder {
  color: #dbe3f0;
}

.bg-inputBorder {
  background-color: #dbe3f0;
}

.clr-inputBg {
  color: #f5f8fe;
}

.bg-inputBg {
  background-color: #f5f8fe;
}

.clr-accentGreen {
  color: #93e396;
}

.bg-accentGreen {
  background-color: #93e396;
}

.clr-secBtn {
  color: #e3ecfc;
}

.bg-secBtn {
  background-color: #e3ecfc;
}

.clr-grey {
  color: #edf1f8;
}

.bg-grey {
  background-color: #edf1f8;
}

.clr-blue-200 {
  color: #fafcff;
}

.bg-blue-200 {
  background-color: #fafcff;
}

.clr-blue-100 {
  color: #f5f8fe;
}

.bg-blue-100 {
  background-color: #f5f8fe;
}

.clr-white {
  color: #fff;
}

.bg-white {
  background-color: #fff;
}

.clr-error {
  color: #ff1111;
}

.bg-error {
  background-color: #ff1111;
}

.clr-accentBlue {
  color: #4682f6;
}

.bg-accentBlue {
  background-color: #4682f6;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

:root,
html {
  scroll-behavior: smooth;
}

::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

::-moz-placeholder, textarea::-moz-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

:-ms-input-placeholder, textarea:-ms-input-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

::-ms-input-placeholder, textarea::-ms-input-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

input,
textarea,
::placeholder,
textarea::placeholder,
a {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

a {
  text-decoration: none;
  display: inline-block;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
button {
  font-weight: bold;
}
h1 span,
h1 p,
h2 span,
h2 p,
h3 span,
h3 p,
h4 span,
h4 p,
h5 span,
h5 p,
h6 span,
h6 p,
a span,
a p,
button span,
button p {
  font-weight: bold;
}

ul {
  list-style-type: none;
}

/* FONT IMPORTS */
@font-face {
  font-family: "Century Gothic";
  src: url("../../fonts/CenturyGothic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Century Gothic";
  src: url("../../fonts/GOTHICBI.TTF") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Century Gothic";
  src: url("../../fonts/GOTHICB.TTF") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.chatBox {
  width: 100%;
  height: 67px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: height 0.4s ease;
  transition: height 0.4s ease;
  overflow: hidden;
}
.chatBox .loading-chats {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  flex-direction: column;
}
.chatBox .loading-chats svg {
  margin-bottom: 0.7rem;
  width: 20px;
  height: 20px;
}
.chatBox.showChat {
  height: 80%;
  max-height: 80%;
}
.chatBox header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0px 2px 8px rgba(5, 39, 105, 0.1);
          box-shadow: 0px 2px 8px rgba(5, 39, 105, 0.1);
  border-radius: 8px 8px 0px 0px;
  padding: 0 0.8rem;
}
.chatBox header .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.chatBox header .left svg {
  color: #052769;
  width: 28px;
  height: 26px;
}
.chatBox header .left h1 {
  margin-left: 1rem;
}
.chatBox .chat-body {
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
  border-radius: 0 0 4px 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding: 1rem 1rem 0.4rem 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f5f8fe;
}
.chatBox .chat-body .chat-wrap {
  padding: 1rem;
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: auto;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.chatBox .chat-body .chat-heading {
  border-bottom: 1px solid #edf1f8;
}
.chatBox .chat-body .chat-heading .top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
}
.chatBox .chat-body .chat-heading img {
  margin-right: 1rem;
  width: 48px;
  height: 48px;
  border-radius: 4px;
}
.chatBox .chat-body .chat-heading h3 {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}
.chatBox .chat-body .chat-heading p {
  font-size: 0.9rem;
  font-weight: 400;
}
.chatBox .chat-body .chat-heading .bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0.5rem 0;
}
.chatBox .chat-body .chat-heading .btn {
  min-width: unset;
  font-size: 0.7rem;
  padding: 0.5rem;
  margin: 0 0.5rem;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}
.chatBox .chat-body .chat-heading .checkbox-wrapper {
  margin-bottom: 0;
}
.chatBox .chat-body .chat-heading .checkbox-wrap {
  padding: 0.4rem;
}
.chatBox .chat-body .chat-heading .checkbox-wrap label {
  font-size: 0.7rem;
}
.chatBox .chat-body .chat-heading .checkbox-wrap input {
  width: 15px;
  height: 15px;
  margin-right: 0.4rem;
  padding: 0;
}
.chatBox .chat-body .chat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 1rem 0;
}
.chatBox .chat-body .chat p {
  font-size: 13px;
  padding: 8px 12px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
}
.chatBox .chat-body .chat .status {
  font-size: 10px;
  margin-top: 0.3rem;
}
.chatBox .chat-body .chat.receive {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.chatBox .chat-body .chat.receive p {
  border-radius: 16px 16px 16px 0px;
}
.chatBox .chat-body .chat.sender {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.chatBox .chat-body .chat.sender p {
  border-radius: 16px 16px 0px 16px;
}
.chatBox .chat-footer form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 0.5rem;
}
.chatBox .chat-footer form .form-group {
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
  width: auto;
}
.chatBox .chat-footer form .btn:not(.btn:last-child),
.chatBox .chat-footer form .form-group {
  margin-right: 0.5rem;
}
.chatBox .chat-footer form .btn {
  width: 40px;
  height: 40px;
  padding: 0;
}

.pd-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  min-height: 100vh;
  background-color: #fafcff;
  margin: auto;
  z-index: -1;
}
.pd-layout-main {
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
  height: 100%;
  padding: 2rem 1rem;
}
.pd-layout-main .inner {
  max-width: 900px;
  margin: auto;
  width: 80%;
}
@media only screen and (max-width: 768px) {
  .pd-layout-main .inner {
    width: 100%;
  }
}
.pd-layout-main-full {
  max-width: unset;
  width: 100%;
}
.pd-layout-sidebar {
  padding: 0rem 0.5rem 0 0.5rem;
  height: 100vh;
  width: 28%;
  min-width: 390px;
  max-width: 400px;
  position: sticky;
  top: 0;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  flex-direction: column;
}
.pd-layout-sidebar.flex-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
@media only screen and (max-width: 900px) {
  .pd-layout {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    padding: 1rem;
  }
  .pd-layout-sidebar, .pd-layout-main {
    width: 100%;
    max-width: unset;
    min-width: unset;
    padding: 2rem 0;
    position: relative;
    height: auto;
  }
}

.btn {
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  background-color: #052769;
  color: white;
  padding: 0 23px;
  min-width: 200px;
  border-radius: 4px;
  border: none;
  margin: 1rem 0;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-weight: 600;
  font-size: 14px;
}
.btn:hover {
  -webkit-box-shadow: 0px 2px 4px rgba(5, 39, 105, 0.1), 0px 4px 6px rgba(5, 39, 105, 0.15);
          box-shadow: 0px 2px 4px rgba(5, 39, 105, 0.1), 0px 4px 6px rgba(5, 39, 105, 0.15);
}
.btn-sec {
  background-color: #edf1f8;
  color: #052769;
}
.btn-sec:hover {
  background-color: #fafcff;
}
.btn-sec:disabled {
  background-color: #edf1f8 !important;
}
.btn.round-btn {
  border-radius: 50px;
  padding: 0.5rem;
  min-width: unset;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  -webkit-box-shadow: unset !important;
          box-shadow: unset !important;
}

.back-icon.round-btn {
  padding: 1rem;
  margin: 0;
  margin-right: 40px;
}
@media only screen and (max-width: 500px) {
  .back-icon.round-btn {
    display: none;
  }
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

:root,
html {
  scroll-behavior: smooth;
}

::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

::-moz-placeholder, textarea::-moz-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

:-ms-input-placeholder, textarea:-ms-input-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

::-ms-input-placeholder, textarea::-ms-input-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

input,
textarea,
::placeholder,
textarea::placeholder,
a {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

a {
  text-decoration: none;
  display: inline-block;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
button {
  font-weight: bold;
}
h1 span,
h1 p,
h2 span,
h2 p,
h3 span,
h3 p,
h4 span,
h4 p,
h5 span,
h5 p,
h6 span,
h6 p,
a span,
a p,
button span,
button p {
  font-weight: bold;
}

ul {
  list-style-type: none;
}

/* FONT IMPORTS */
@font-face {
  font-family: "Century Gothic";
  src: url("../../fonts/CenturyGothic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Century Gothic";
  src: url("../../fonts/GOTHICBI.TTF") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Century Gothic";
  src: url("../../fonts/GOTHICB.TTF") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.clr-primary {
  color: #052769;
}

.bg-primary {
  background-color: #052769;
}

.clr-d-blue {
  color: #0c2045;
}

.bg-d-blue {
  background-color: #0c2045;
}

.clr-placeholder {
  color: #6b7c9e;
}

.bg-placeholder {
  background-color: #6b7c9e;
}

.clr-inputBorder {
  color: #dbe3f0;
}

.bg-inputBorder {
  background-color: #dbe3f0;
}

.clr-inputBg {
  color: #f5f8fe;
}

.bg-inputBg {
  background-color: #f5f8fe;
}

.clr-accentGreen {
  color: #93e396;
}

.bg-accentGreen {
  background-color: #93e396;
}

.clr-secBtn {
  color: #e3ecfc;
}

.bg-secBtn {
  background-color: #e3ecfc;
}

.clr-grey {
  color: #edf1f8;
}

.bg-grey {
  background-color: #edf1f8;
}

.clr-blue-200 {
  color: #fafcff;
}

.bg-blue-200 {
  background-color: #fafcff;
}

.clr-blue-100 {
  color: #f5f8fe;
}

.bg-blue-100 {
  background-color: #f5f8fe;
}

.clr-white {
  color: #fff;
}

.bg-white {
  background-color: #fff;
}

.clr-error {
  color: #ff1111;
}

.bg-error {
  background-color: #ff1111;
}

.clr-accentBlue {
  color: #4682f6;
}

.bg-accentBlue {
  background-color: #4682f6;
}

.icon-active {
  position: relative;
}
.icon-active::after {
  content: "";
  position: absolute;
  top: -1px;
  right: 0px;
  height: 6px;
  width: 6px;
  background-color: #ff1111;
  border-radius: 50%;
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-shadow: 0px 2px 8px rgba(5, 38, 105, 0.1);
          box-shadow: 0px 2px 8px rgba(5, 38, 105, 0.1);
  padding: 1rem 2rem;
  position: relative;
  inset: 0;
  z-index: 10;
}
.nav .drop-down-wrap {
  position: relative;
}
.nav .drop-down-wrap .drop-down {
  width: 90%;
  position: absolute;
  left: 50%;
  top: 100%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 1rem;
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 8px rgba(5, 38, 105, 0.1);
          box-shadow: 0px 2px 8px rgba(5, 38, 105, 0.1);
  background-color: white;
  -webkit-clip-path: inset(0 0 100% 0);
          clip-path: inset(0 0 100% 0);
  -webkit-transition: -webkit-clip-path 0.4s ease;
  transition: -webkit-clip-path 0.4s ease;
  transition: clip-path 0.4s ease;
  transition: clip-path 0.4s ease, -webkit-clip-path 0.4s ease;
}
.nav .drop-down-wrap .drop-down.show-drop-down {
  -webkit-clip-path: inset(0 0 0 0);
          clip-path: inset(0 0 0 0);
}
@media only screen and (max-width: 500px) {
  .nav .drop-down-wrap .drop-down {
    min-width: 120px;
    right: 0;
    left: unset;
    -webkit-transform: unset;
            transform: unset;
  }
}
.nav .drop-down-wrap .drop-down button,
.nav .drop-down-wrap .drop-down a,
.nav .drop-down-wrap .drop-down p {
  width: 100%;
  display: block;
  color: #052769;
  margin-bottom: 0.7rem;
  background-color: transparent;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 15px;
  padding: 0;
}
.nav .drop-down-wrap .drop-down button:last-child,
.nav .drop-down-wrap .drop-down a:last-child,
.nav .drop-down-wrap .drop-down p:last-child {
  margin-bottom: 0;
}
.nav nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.nav .nav-ctrl-btn {
  display: none;
  width: 30px;
  height: 30px;
}
@media only screen and (max-width: 900px) {
  .nav .nav-ctrl-btn {
    display: block;
  }
}
.nav .nav-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.nav .nav-left .round-btn {
  margin: 0;
  margin-right: 0.5rem;
}
.nav img.logo {
  width: 80px;
  height: 35px;
}
@media only screen and (max-width: 500px) {
  .nav img.logo {
    width: 65px;
    height: 30px;
  }
}
.nav .nav_link {
  margin: 0 1rem;
  font-weight: 500;
  font-size: 0.9rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.nav .nav_link a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
@media only screen and (max-width: 900px) {
  .nav .nav_link {
    width: 100%;
    padding: 0.6em 0;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    font-size: 1rem;
    margin: 0.5rem 0;
  }
}
.nav .drop-down-wrap {
  margin-left: 2rem;
}
.nav .profile-icon {
  padding: 0.6rem;
  color: #052769;
}
.nav .profile-icon img {
  width: 18px;
  height: 18px;
  border-radius: 5px;
}
.nav .profile-icon p {
  font-size: 13px;
  margin-left: 0.5rem;
}
@media only screen and (max-width: 500px) {
  .nav .profile-icon p {
    display: none;
  }
}
.nav .profile-icon svg {
  margin-left: 1rem;
}
.nav ul.nav_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
  font-weight: bold;
}
@media only screen and (max-width: 900px) {
  .nav ul.nav_list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    position: absolute;
    inset: 0;
    -webkit-box-shadow: 0px 2px 8px rgba(5, 38, 105, 0.1);
            box-shadow: 0px 2px 8px rgba(5, 38, 105, 0.1);
    top: 72px;
    background-color: white;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 20;
    padding: 0 1.5rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-transition: -webkit-clip-path 0.4s ease;
    transition: -webkit-clip-path 0.4s ease;
    transition: clip-path 0.4s ease;
    transition: clip-path 0.4s ease, -webkit-clip-path 0.4s ease;
    -webkit-clip-path: inset(0 0 100% 0);
            clip-path: inset(0 0 100% 0);
  }
  .nav ul.nav_list.nav-opened {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0);
  }
}
.nav ul.nav_list .nav-ctrl-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.nav .icon-list-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
@media only screen and (max-width: 900px) {
  .nav .icon-list-wrap a {
    margin: 0rem 0.5rem;
  }
  .nav .icon-list-wrap a span {
    display: none;
  }
}
.nav .btn-wrap {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.nav .btn-wrap .btn {
  margin: 0 0.5rem;
  font-weight: 500;
  min-width: unset;
  padding: 8px 16px;
  font-size: 0.9rem;
  height: auto;
}
@media only screen and (max-width: 500px) {
  .nav .btn-wrap .btn {
    margin: 0;
    margin-left: 0.5rem;
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 900px) {
  .nav .btn-wrap {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-left: 0;
  }
}
@media only screen and (max-width: 900px) {
  .nav {
    padding: 1rem;
  }
}

.userimg {
  width: 24px;
  height: 24px;
}

.input-icon-wrap {
  background: #f5f8fe;
  border: 1px solid #dbe3f0;
  width: 100%;
  border-radius: 4px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #6b7c9e;
  cursor: pointer;
  position: relative;
}
.input-icon-wrap.icon-placeholder input {
  padding-left: 40px;
}
.input-icon-wrap.icon-placeholder .icon-left {
  cursor: text;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  color: #6b7c9e;
}
.input-icon-wrap .icon {
  height: 100%;
  background: transparent;
  cursor: pointer;
  padding: 0 0.6rem;
  border: none !important;
  color: #052769;
}
.input-icon-wrap input {
  background: transparent;
  border: none !important;
  height: 95% !important;
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
}

.form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin: 1rem 0;
  width: 100%;
}
.form-group.except {
  margin-bottom: 10px;
}
.form-group label {
  font-weight: 600;
  color: #052769;
  font-size: 0.8rem;
  margin: 0.2rem 0 0.5rem 0;
  color: #052769;
}

input:not([type=checkbox]),
select,
textarea {
  background: #f5f8fe;
  border: 1px solid #dbe3f0;
  width: 100%;
  height: 40px;
  padding: 0 0.5rem;
  border-radius: 4px;
  color: #222 !important;
}
input:not([type=checkbox]):focus,
select:focus,
textarea:focus {
  outline: none;
}

.form-group textarea {
  height: 130px !important;
  padding: 0.5rem;
  resize: vertical;
}

.react-select {
  width: 100% !important;
}
.react-select:focus {
  outline: none !important;
  border: 1px solid #dbe3f0 !important;
}
.react-select__control {
  background: #f5f8fe !important;
  border: 1px solid #dbe3f0 !important;
  width: 100%;
  height: 40px;
  padding: 0 0.5rem;
  border-radius: 4px;
  color: #6b7c9e;
}
.react-select__menu {
  width: 50%;
  max-width: 300px;
}
.react-select__indicators, .react-select__value-container {
  height: 100%;
}
.react-select__multi-value {
  border-radius: 15px !important;
  background-color: #93e3964d !important;
  color: #007b30;
  border: 0.5px solid #007b30;
  padding: 0.1rem 0.4rem;
}
.react-select__multi-value__label {
  padding: 0rem 0.3rem !important;
  padding-right: 0.5rem;
  font-size: 0.9rem !important;
  border-right: 1px solid #007b30;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  border-radius: 0 !important;
}
.react-select__multi-value__remove {
  cursor: pointer;
}
.react-select__multi-value__remove:hover {
  color: #007b30 !important;
  background-color: transparent !important;
}

.input-err-msg {
  color: #f2994a;
  font-size: 0.8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 0.3rem;
}

.pd-login .pd-layout-main {
  min-height: calc(100vh - 67px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.pd-login form {
  width: 95%;
  max-width: 500px;
  padding: 2rem 1.5rem;
  margin: auto;
}
.pd-login form h1 {
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 2rem;
}
.pd-login form .btn {
  width: 100%;
  margin-top: 1.5rem;
}
.pd-login .message_modal {
  padding: 2rem;
  border-radius: 8px;
  max-width: 470px;
  width: 95%;
  margin: 1rem auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  flex-direction: column;
  -webkit-box-shadow: 0px 1px 4px rgba(5, 39, 105, 0.05);
          box-shadow: 0px 1px 4px rgba(5, 39, 105, 0.05);
  min-height: 300px;
}
.pd-login .message_modal .iconWrap {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.pd-login .message_modal img {
  width: 50px;
  height: 40px;
  margin: auto;
  display: inline-block;
}
.pd-login .message_modal h2 {
  margin: 1rem auto;
  text-align: center;
}
.pd-login .message_modal p {
  font-size: 0.9rem;
}
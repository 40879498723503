*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

:root,
html {
  scroll-behavior: smooth;
}

::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

::-moz-placeholder, textarea::-moz-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

:-ms-input-placeholder, textarea:-ms-input-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

::-ms-input-placeholder, textarea::-ms-input-placeholder {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

input,
textarea,
::placeholder,
textarea::placeholder,
a {
  font-family: "Century Gothic", "Open Sans", "Segoe UI", sans-serif;
}

a {
  text-decoration: none;
  display: inline-block;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
button {
  font-weight: bold;
}
h1 span,
h1 p,
h2 span,
h2 p,
h3 span,
h3 p,
h4 span,
h4 p,
h5 span,
h5 p,
h6 span,
h6 p,
a span,
a p,
button span,
button p {
  font-weight: bold;
}

ul {
  list-style-type: none;
}

/* FONT IMPORTS */
@font-face {
  font-family: "Century Gothic";
  src: url("../../fonts/CenturyGothic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Century Gothic";
  src: url("../../fonts/GOTHICBI.TTF") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Century Gothic";
  src: url("../../fonts/GOTHICB.TTF") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.clr-primary {
  color: #052769;
}

.bg-primary {
  background-color: #052769;
}

.clr-d-blue {
  color: #0c2045;
}

.bg-d-blue {
  background-color: #0c2045;
}

.clr-placeholder {
  color: #6b7c9e;
}

.bg-placeholder {
  background-color: #6b7c9e;
}

.clr-inputBorder {
  color: #dbe3f0;
}

.bg-inputBorder {
  background-color: #dbe3f0;
}

.clr-inputBg {
  color: #f5f8fe;
}

.bg-inputBg {
  background-color: #f5f8fe;
}

.clr-accentGreen {
  color: #93e396;
}

.bg-accentGreen {
  background-color: #93e396;
}

.clr-secBtn {
  color: #e3ecfc;
}

.bg-secBtn {
  background-color: #e3ecfc;
}

.clr-grey {
  color: #edf1f8;
}

.bg-grey {
  background-color: #edf1f8;
}

.clr-blue-200 {
  color: #fafcff;
}

.bg-blue-200 {
  background-color: #fafcff;
}

.clr-blue-100 {
  color: #f5f8fe;
}

.bg-blue-100 {
  background-color: #f5f8fe;
}

.clr-white {
  color: #fff;
}

.bg-white {
  background-color: #fff;
}

.clr-error {
  color: #ff1111;
}

.bg-error {
  background-color: #ff1111;
}

.clr-accentBlue {
  color: #4682f6;
}

.bg-accentBlue {
  background-color: #4682f6;
}

.info-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.info-wrap .btn.round-btn {
  padding: 0.7rem;
  margin: 0;
  display: none;
}
@media only screen and (max-width: 900px) {
  .info-wrap .btn.round-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.msg-info {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0.3rem 0;
  padding: 1rem;
}
.msg-info img.info-img {
  width: 70px;
  height: 70px;
  margin-right: 15px;
  border-radius: 8px;
}
.msg-info .title {
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 17px;
  margin-bottom: 5px;
}
.msg-info .message {
  color: #8595b2;
}

.pd-chats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow-x: hidden;
}
.pd-chats .back-icon.round-btn {
  margin-top: 1.5rem;
  margin-right: 0;
  margin-left: 1rem;
}
@media (max-width: 1270px) {
  .pd-chats .back-icon.round-btn {
    display: none;
  }
}
.pd-chats .emptyChats {
  height: 100%;
}
.pd-chats .emptyChats .chat-head {
  -webkit-box-shadow: unset;
          box-shadow: unset;
  padding: 1rem 0.8rem;
}
.pd-chats .emptyChats .chat-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  background-color: unset;
}
.pd-chats .emptyChats .btn.round-btn {
  padding: 0.7rem;
  margin: 0;
  display: none;
}
@media only screen and (max-width: 900px) {
  .pd-chats .emptyChats .btn.round-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.pd-chats .chat-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.pd-chats .chat-title .back-icon.round-btn {
  margin: 0;
  margin-right: 1.5rem;
  display: none;
}
@media (max-width: 1270px) {
  .pd-chats .chat-title .back-icon.round-btn {
    display: block;
  }
}
@media only screen and (max-width: 900px) {
  .pd-chats .chat-title {
    padding: 0.5rem 0.5rem 0 0.5rem;
  }
  .pd-chats .chat-title h2.chat-text {
    margin-top: 0 !important;
  }
}
.pd-chats main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 1200px;
  width: 98%;
  height: calc(100vh - 76px);
  position: sticky;
  top: 0;
  margin: auto;
}
.pd-chats main .chats-info {
  width: 35%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-right: 1rem;
}
@media only screen and (max-width: 900px) {
  .pd-chats main .chats-info {
    width: 100%;
  }
}
.pd-chats main .chats-info h2.chat-text {
  margin-top: 1.5rem;
}
.pd-chats main .chats-info .chat-info-wrap {
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
  overflow-y: auto;
}
.pd-chats main .chats-info .chat-info-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #f0f2f3;
}
.pd-chats main .chats-info .chat-info-wrap::-webkit-scrollbar {
  height: 7px;
  width: 7px;
  background: #8198b1;
}
.pd-chats main .chats-info .chat-info-wrap::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #8198b1;
}
.pd-chats main .msg-info {
  margin: 0;
  border-bottom: 1px solid #edf1f8;
}
.pd-chats main .msg-info img.info-img {
  width: 48px;
  height: 48px;
}
.pd-chats main .msg-info .title {
  font-size: 18px;
}
.pd-chats main .msg-info .message {
  font-size: 14px;
}
.pd-chats main .chatbox-wrapper {
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
}
@media only screen and (max-width: 900px) {
  .pd-chats main .chatbox-wrapper {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    inset: 0;
    -webkit-transform: translateX(100vw);
            transform: translateX(100vw);
    height: 100%;
    -webkit-transition: -webkit-transform 0.5s ease;
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  }
  .pd-chats main .chatbox-wrapper.show-chat {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
.pd-chats main .chatbox-wrapper .chatBox .chat-body .chat-wrap {
  height: auto;
}
.pd-chats main .chatbox-wrapper .chatBox .chat-body .chat-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #f0f2f3;
}
.pd-chats main .chatbox-wrapper .chatBox .chat-body .chat-wrap::-webkit-scrollbar {
  height: 7px;
  width: 7px;
  background: #8198b1;
}
.pd-chats main .chatbox-wrapper .chatBox .chat-body .chat-wrap::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #8198b1;
}

.rateComponent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  background-color: #4682f6;
  padding: 30px 0;
}
.rateComponent p {
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  margin-bottom: 10px;
}

.chat-body::-webkit-scrollbar-track,
.chat-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #f0f2f3;
}
.chat-body::-webkit-scrollbar,
.chat-wrap::-webkit-scrollbar {
  height: 7px;
  width: 7px;
  background: #8198b1;
}
.chat-body::-webkit-scrollbar-thumb,
.chat-wrap::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #8198b1;
}
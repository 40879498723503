.pagination-ctrl {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 2rem auto;
  padding: 1rem;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.pagination-ctrl .btn {
  min-width: unset;
  font-size: 0.9rem;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0 0.3rem;
  font-weight: 600;
}

.all-services .top-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 2rem;
}
.all-services .top-section .right {
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
  margin-right: 2rem;
  max-width: 65%;
}
.all-services .top-section .filter-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.all-services .top-section .filter-wrap p {
  margin-right: 1rem;
}
.all-services .top-section .filter-wrap .btn {
  margin: 0 0.3rem;
  font-size: 0.8rem;
}
.all-services .top-section .btn {
  min-width: unset;
  font-size: 0.9rem;
  font-weight: 600;
}
@media only screen and (max-width: 500px) {
  .all-services .top-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .all-services .top-section .right,
.all-services .top-section .left,
.all-services .top-section .btn {
    max-width: unset;
    width: 100%;
  }
  .all-services .top-section .btn {
    margin: 1rem 0;
    margin-bottom: 2rem;
    height: 49px;
  }
}
.all-services .search-box {
  height: 45px;
  border-radius: 4px;
}
.all-services .search-box ::-webkit-input-placeholder {
  color: #bac9e3 !important;
}
.all-services .search-box ::-moz-placeholder {
  color: #bac9e3 !important;
}
.all-services .search-box :-ms-input-placeholder {
  color: #bac9e3 !important;
}
.all-services .search-box ::-ms-input-placeholder {
  color: #bac9e3 !important;
}
.all-services .search-box svg,
.all-services .search-box ::placeholder {
  color: #bac9e3 !important;
}
.all-services small {
  margin: 1rem 0;
  margin-top: 4rem;
  font-size: 10px;
}
.all-services .service-wrap {
  margin-top: 0.5rem;
}
@media only screen and (max-width: 500px) {
  .all-services .pd-layout-main {
    padding-top: 0;
  }
  .all-services .pd-layout-sidebar {
    display: none;
  }
}

.noService {
  width: 100%;
  text-align: center;
  min-height: 60vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  flex-direction: column;
}
.noService .coverBag {
  background: #edf1f8;
  width: 64px;
  margin: 0;
  margin-bottom: 10px;
  height: 64px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.noService .coverBag svg,
.noService .coverBag img {
  width: 45%;
  height: 45%;
}
.noService p.noText {
  font-size: 14px;
  line-height: 17px;
  color: #6b7c9e;
  margin-bottom: 20px;
}
.noService .noPost {
  padding: 13px 0px;
  border: transparent;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  background: #052769;
  width: 158px;
  color: white;
}
.noService.error .coverBag {
  width: 100px;
  height: 100px;
}
.noService.error p.noText {
  font-size: 1.2rem;
  margin: 1rem 0;
  font-weight: 500;
}